import imgProxy from "~/utils/imgproxy";
import { createSignal, For, Show } from "solid-js";
import { urlRs } from "~/utils/url";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { useEventsContext } from "~/contexts/EventsContext";
import { InputTypeText } from "~/components/Forms/Fields/Base/InputTypeText";
import Accordion from "@corvu/accordion";
import {
  AutopromosRenderer,
  mayHaveShortcode,
} from "~/components/shared/Autopromos/AutopromosRenderer";

import type { Faq } from "~/types/drupal_jsonapi";

import "~/components/shared/WysiwygComponents.css";
import "./FrontCogedimAndYou.css";

import IconDashboardCustomize from "~/img/icons/front-page/dashboard_customize.svg";
import IconAssignmentInd from "~/img/icons/front-page/assignment_ind.svg";
import IconRedeem from "~/img/icons/front-page/redeem.svg";
import IconHouse from "~/img/icons/front-page/house.svg";
import IconEuro from "~/img/icons/euro.svg";
import IconGroupOutline from "~/img/icons/group_outline.svg";
import IconExpandMore from "~/img/icons/expand_more.svg";
import IconHandshake from "~/img/icons/handshake.svg";
import IconChairOutline from "~/img/icons/chair_outline.svg";
import AltareaLogo from "~/img/logos/altarea-logo.svg";
import IconChevronLeft from "~/img/icons/chevron_left.svg";
import IconChevronRight from "~/img/icons/chevron_right.svg";

export default function FrontCogedimAndYou(props: { faqs: Faq[] }) {
  return (
    <>
      <div class="outer-front-cogedim-and-you">
        <section class="front-cogedim-and-you">
          <div class="content-part" data-test="cogedim">
            <h2 class="front-h2 main">
              Cogedim <strong>& vous</strong>
            </h2>

            <FollowCogedim />
            <WhoIsCogedim />
            <ReviewsCogedim />
            <FrontFAQ faqs={props.faqs} />
          </div>
        </section>
      </div>
    </>
  );
}

function FollowCogedim() {
  const [isExpanded, setIsExpanded] = createSignal(false);

  return (
    <>
      <div class="content-block follow" data-test="cogedim-services">
        <h2 class="front-h2">
          <span class="dash" />
          Cogedim
          <strong>vous accompagne</strong>
        </h2>
        <picture class="visual">
          <img
            src={imgProxy(
              "/images/front-page/front-cogedim-et-vous-visual-01.jpg",
              `size:400:400/resizing_type:fill`,
            )}
            alt=""
            height="200"
            width="200"
            loading="lazy"
          />
        </picture>
        <div class="services" data-test="services">
          <div class="service" data-test="service">
            <i aria-hidden="true" class="cog-icon scale2">
              <IconDashboardCustomize />
            </i>
            <h3>
              <a
                href={urlRs(
                  "journeys",
                  "/cogedim-pour-vous/pourquoi-choisir-cogedim/des-logements-reellement-differents.html",
                )}
              >
                Numéro 1 de la personnalisation
              </a>
            </h3>
            <a
              href={urlRs(
                "journeys",
                "/cogedim-pour-vous/pourquoi-choisir-cogedim/des-logements-reellement-differents.html",
              )}
              class="more-link"
            >
              <span>En savoir</span>+
            </a>
          </div>
          <div class="service" data-test="service">
            <i aria-hidden="true" class="cog-icon scale2">
              <IconAssignmentInd />
            </i>
            <h3>
              <a
                href={urlRs(
                  "journeys",
                  "/cogedim-pour-vous/pourquoi-choisir-cogedim/un-accompagnement-sur-mesure.html",
                )}
              >
                Un suivi personnalisé, de A à Z
              </a>
            </h3>
            <a
              href={urlRs(
                "journeys",
                "/cogedim-pour-vous/pourquoi-choisir-cogedim/un-accompagnement-sur-mesure.html",
              )}
              class="more-link"
            >
              <span>En savoir</span>+
            </a>
          </div>
          <div class="service" data-test="service">
            <i aria-hidden="true" class="cog-icon scale2">
              <IconRedeem />
            </i>
            <h3>
              <a
                href={urlRs(
                  "articles",
                  "/le-neuf-decrypte/l-actu-du-neuf/cogedim-toujours-pres-de-ses-clients.html",
                )}
              >
                Le Club Cogedim
              </a>
            </h3>
            <a
              href={urlRs(
                "articles",
                "/le-neuf-decrypte/l-actu-du-neuf/cogedim-toujours-pres-de-ses-clients.html",
              )}
              class="more-link"
            >
              <span>En savoir</span>+
            </a>
          </div>
          <div
            class="expandable-service"
            classList={{ expanded: isExpanded() }}
            data-test="service"
          >
            <i aria-hidden="true" class="cog-icon scale2">
              <IconHouse />
            </i>
            <h3>
              <button
                type="button"
                onClick={() => setIsExpanded(!isExpanded())}
              >
                Toutes les solutions d’Altarea Solutions & Services
              </button>
            </h3>
            <button type="button" onClick={() => setIsExpanded(!isExpanded())}>
              <i aria-hidden="true" class="cog-icon">
                <IconExpandMore />
              </i>
            </button>
            <div class="altarea-solutions">
              <div class="solutions" data-test="solutions">
                <a
                  href={urlRs(
                    "journeys",
                    "/cogedim-pour-vous/nos-services/un-financement-simplifie.html",
                  )}
                >
                  <i aria-hidden="true" class="cog-icon square">
                    <IconEuro />
                  </i>
                  Financement
                </a>
                <a
                  href={urlRs(
                    "journeys",
                    "/cogedim-pour-vous/nos-services/une-gestion-locative-cle-en-main.html",
                  )}
                >
                  <i aria-hidden="true" class="cog-icon square">
                    <IconGroupOutline />
                  </i>
                  Gestion locative
                </a>
                <a
                  href={urlRs(
                    "journeys",
                    "/besoin-particulier-conciergerie-soccupe-de.html",
                  )}
                >
                  <i aria-hidden="true" class="cog-icon square">
                    <IconHandshake />
                  </i>
                  La conciergerie
                </a>
                <a
                  href={urlRs(
                    "journeys",
                    "/investir/les-solutions-pour-investir-dans-le-neuf/la-solution-meublee/",
                  )}
                >
                  <i aria-hidden="true" class="cog-icon square">
                    <IconChairOutline />
                  </i>
                  Solution meublée
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function WhoIsCogedim() {
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <div class="content-block who-is-cogedim" data-test="company">
        <h2 class="front-h2">
          <span class="dash" />
          Qui est <strong>Cogedim ?</strong>
        </h2>
        <div class="cogedim-cards" data-test="cards">
          <a
            class="cogedim-card pink"
            href={urlRs(
              "journeys",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/",
            )}
          >
            <strong>+60 ans</strong>
            d’existence
          </a>

          <Show
            when={settingsContext.escda_enabled}
            fallback={
              <a
                class="cogedim-card blue"
                href={urlRs(
                  "journeys",
                  "/cogedim-pour-vous/pourquoi-choisir-cogedim/",
                )}
              >
                <strong>+&nbsp;120&nbsp;000</strong> logements réalisés
              </a>
            }
          >
            <a
              class="cogedim-card blue"
              href={urlRs(
                "journeys",
                "/cogedim-pour-vous/pourquoi-choisir-cogedim/un-accompagnement-sur-mesure.html",
              )}
            >
              <strong>
                7<sup>ème</sup> année
              </strong>{" "}
              consécutive élu service client
              <br />
              de l’année
            </a>
          </Show>

          <span
            class="cogedim-card purple with-padding"
            data-test="cogedim-card"
          >
            <span class="altarea-logo">
              <AltareaLogo />
            </span>
            La force et le savoir-faire d’un groupe leader de la transformation
            urbaine en France
          </span>
        </div>
      </div>
    </>
  );
}

function ReviewsCogedim() {
  const settingsContext = useDrupalSettingsContext();
  const [viewportSizeProvider] = useViewportSizeContext();

  const SlidesCount = 8;
  const [sliderMobilePosition, setSliderMobilePosition] = createSignal(0);
  const [sliderDesktopPosition, setSliderDesktopPosition] = createSignal(0);

  function goPreviousMobile() {
    if (sliderMobilePosition() > 0) {
      setSliderMobilePosition(sliderMobilePosition() - 1);
    }
  }

  function goNextMobile() {
    if (sliderMobilePosition() < SlidesCount - 1) {
      setSliderMobilePosition(sliderMobilePosition() + 1);
    }
  }

  function goPreviousDesktop() {
    if (sliderDesktopPosition() > 0) {
      setSliderDesktopPosition(sliderDesktopPosition() - 1);
    }
  }

  function goNextDesktop() {
    if (sliderDesktopPosition() < Math.ceil(SlidesCount / 2) - 1) {
      setSliderDesktopPosition(sliderDesktopPosition() + 1);
    }
  }

  return (
    <>
      <div class="content-block reviews-cogedim" data-test="customer-reviews">
        <h2
          class="front-h2 with-reviews"
          classList={{ "with-logo": settingsContext.escda_enabled }}
        >
          <span class="dash" />
          Des milliers <strong>de clients satisfaits</strong>
        </h2>

        <div class="reviews" data-test="reviews">
          <div id="23490dae-3dbd-427e-9de3-6a588dc7a2a2" />
          <script
            async
            charset="utf-8"
            src="https://widgets.rr.skeepers.io/generated/bf5c3d00-bd34-3784-356b-65e9f019e959/23490dae-3dbd-427e-9de3-6a588dc7a2a2.js"
          />
        </div>

        <div
          class={`reviews-slider slide-mobile-${sliderMobilePosition()} slide-desktop-${sliderDesktopPosition()}`}
        >
          <div class="buttons">
            <Show
              when={viewportSizeProvider.viewPortIsLessThan768}
              fallback={
                <>
                  <button
                    type="button"
                    class="prev-desktop"
                    onClick={() => goPreviousDesktop()}
                  >
                    <i aria-hidden="true" class="cog-icon">
                      <IconChevronLeft />
                    </i>
                  </button>
                  <button
                    type="button"
                    class="next-desktop"
                    onClick={() => goNextDesktop()}
                  >
                    <i aria-hidden="true" class="cog-icon">
                      <IconChevronRight />
                    </i>
                  </button>
                </>
              }
            >
              <button
                type="button"
                class="prev-mobile"
                onClick={() => goPreviousMobile()}
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconChevronLeft />
                </i>
              </button>
              <button
                type="button"
                class="next-mobile"
                onClick={() => goNextMobile()}
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconChevronRight />
                </i>
              </button>
            </Show>
          </div>

          <div class="review-cards" data-test="cards">
            <div class="review-card" data-test="card-review">
              <h3>«&nbsp;Très heureux&nbsp;»</h3>
              <img
                src="/images/front-page/immo-rating-5.png"
                alt=""
                width="105"
                height="16"
              />
              <p>
                Cogedim a toujours répondu dans un temps très court à mes mails
                et m’a tenu régulièrement informé des travaux. Je suis très
                satisfait.
              </p>
              <p class="submitted">
                Publié le 23 juin 2023
                <br />
                SébastienL, propriétaire à Paris
              </p>
            </div>

            <div class="review-card" data-test="card-review">
              <h3>«&nbsp;Excellents service & communication&nbsp;»</h3>
              <img
                src="/images/front-page/immo-rating-5.png"
                alt=""
                width="105"
                height="16"
              />
              <p>
                Sur la durée du projet, Cogedim a assuré un excellent suivi en
                toute transparence.
              </p>
              <p class="submitted">
                Publié le 29 juillet 2023
                <br />
                GaetanS, propriétaire à Marigny-Saint-Marcel – Savoie-Léman
              </p>
            </div>

            <div class="review-card" data-test="card-review">
              <h3>«&nbsp;Construction de qualité et excellent suivi&nbsp;»</h3>
              <img
                src="/images/front-page/immo-rating-5.png"
                alt=""
                width="105"
                height="16"
              />
              <p>
                Nous sommes très satisfaits de la qualité du logement, ainsi que
                du suivi global de l’équipe Cogedim. Beaucoup d’attention portée
                à nos demandes, bonne gestion des aléas de chantier. Le logement
                final correspond bien à ce qui était prévu et nous ne sommes pas
                déçus.
              </p>
              <p class="submitted">
                Publié le 12 juillet 2023
                <br />
                CharlesZ, propriétaire à Montigny-le-Bretonneux – Île-de-France
              </p>
            </div>

            <div class="review-card" data-test="card-review">
              <h3>«&nbsp;Service professionnel&nbsp;»</h3>
              <img
                src="/images/front-page/immo-rating-5.png"
                alt=""
                width="105"
                height="16"
              />
              <p>
                Très bon accueil et parfaite connaissance des produits, avec de
                très bons conseils.
              </p>
              <p class="submitted">
                Publié le 2 août 2023
                <br />
                FrédéricR, propriétaire à Droussard – Savoie-Léman
              </p>
            </div>

            <div class="review-card" data-test="card-review">
              <h3>«&nbsp;Prestation de qualité&nbsp;»</h3>
              <img
                src="/images/front-page/immo-rating-5.png"
                alt=""
                width="105"
                height="16"
              />
              <p>
                Respect des délais, prestations de qualité, interlocuteurs
                attentifs et qualifiés. Rien à dire. Bravo.
              </p>
              <p class="submitted">
                Publié le 29 juin 2023
                <br />
                DenisP, propriétaire en Provence
              </p>
            </div>

            <div class="review-card" data-test="card-review">
              <h3>«&nbsp;Compétence, rigueur et disponibilité&nbsp;»</h3>
              <img
                src="/images/front-page/immo-rating-5.png"
                alt=""
                width="105"
                height="16"
              />
              <p>
                Je recommande pour la qualité des prestations et le suivi du
                projet. Les interlocuteurs sont compétents et réactifs.
              </p>
              <p class="submitted">
                Publié le 20 juillet 2023
                <br />
                CarolineN, propriétaire à Nantes
              </p>
            </div>

            <div class="review-card" data-test="card-review">
              <h3>«&nbsp;Satisfait&nbsp;»</h3>
              <img
                src="/images/front-page/immo-rating-5.png"
                alt=""
                width="105"
                height="16"
              />
              <p>
                Tout s'est bien passé avec Cogedim. Bonne communication et
                appartement conforme à ce qui était attendu.
              </p>
              <p class="submitted">
                Publié le 19 juillet 2023
                <br />
                VivienG, propriétaire à Nantes
              </p>
            </div>

            <div class="review-card" data-test="card-review">
              <h3>«&nbsp;Rapide et efficace&nbsp;»</h3>
              <img
                src="/images/front-page/immo-rating-5.png"
                alt=""
                width="105"
                height="16"
              />
              <p>
                Rien à redire, tout était parfait : le sérieux, la courtoisie,
                la comprétence. Bravo à tous, continuez ainsi. Merci.
              </p>
              <p class="submitted">
                Publié le 24 juin 2023
                <br />
                CharlesC, propriétaire à Schiltigheim
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FrontFAQ(props: { faqs: Faq[] }) {
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <div class="content-block faqs" data-test="faqs">
        <h2 class="front-h2">
          <span class="dash" />
          Toutes nos réponses
          <strong>à vos questions</strong>
        </h2>
        <picture class="faq-visual">
          <img
            src={imgProxy(
              "/images/front-page/front-cogedim-et-vous-visual-02.jpg",
              `size:400:400/resizing_type:fill`,
            )}
            alt=""
            height="200"
            width="200"
            loading="lazy"
          />
        </picture>
        <form
          method="get"
          action={urlRs("faq", "/faq/")}
          class="faq-form"
          data-test="faqs-search"
        >
          <InputTypeText
            name="search"
            label="Mot-clé ou question"
            help={`Exemples de recherche : "TVA réduite", "PTZ", "Pinel"`}
          />
          <button
            type="submit"
            class="btn form-submit"
            data-test="btn-submit"
            onClick={() => {
              sendClick("btn-faqs-search", "faq");
            }}
          >
            Rechercher
          </button>
        </form>
        <div class="faq-list" data-test="faqs-promoted">
          <Accordion collapseBehavior="hide">
            <For each={props.faqs}>
              {(faq) => (
                <Accordion.Item as="div" class="node-qa" data-test="qa">
                  <Accordion.Trigger
                    as="h2"
                    onClick={() => {
                      sendClick("btn-faq", "faq");
                    }}
                    class="question-h2"
                    data-test="question"
                  >
                    {faq.title}
                    <i aria-hidden="true" class="cog-icon">
                      <IconExpandMore />
                    </i>
                  </Accordion.Trigger>
                  <Accordion.Content data-test="answer">
                    <Show
                      when={mayHaveShortcode(faq.field_answer.value)}
                      fallback={
                        <div
                          class="answer-content"
                          innerHTML={faq.field_answer.value}
                        />
                      }
                    >
                      <div class="answer-content">
                        <AutopromosRenderer
                          text={faq.field_answer.value}
                          fromFieldName="field_text"
                        />
                      </div>
                    </Show>
                  </Accordion.Content>
                </Accordion.Item>
              )}
            </For>
          </Accordion>
        </div>
        <div class="more-link">
          <a
            href={urlRs("faq", "/faq/")}
            onClick={() => {
              sendClick(`btn-faqs`, "faq");
            }}
            data-test="btn-more"
          >
            Voir toutes les FAQ
          </a>
        </div>
      </div>
    </>
  );
}
